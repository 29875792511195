/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        function scrollToId(hash) {
            var aTag = $("'#"+ hash + "'");
            $('html,body').animate({
                scrollTop: aTag.offset().top
            }, 'slow');
        }
        
        $(".chart-scroll").click(function(){
            var aTag = $("#chart");
            $('html,body').animate({
                scrollTop: aTag.offset().top
            }, 'slow');
        });

/*        $(".dropdown-menu a").click(function(){
          $(this).unbind('click');
        });*/

        /*mobile dropdown bootstrap freeze fix */

          //var width = $(window).width();
          //console.log(width);
          //
          //if ( width < 769 ) {
          //    $('.dropdown').bind('touchstart touchend click', function (e) {
          //        e.stopPropagation();
          //        var shown = $(this).attr('shown');
          //        var menu = $(this).find(".dropdown-menu");
          //        console.log(shown);
          //        if (!shown || shown === 'false') {
          //            menu.show();
          //            $(this).attr('shown', 'true');
          //        }
          //        if (shown === 'true') {
          //            menu.hide();
          //            $(this).attr('shown', 'false');
          //        }
          //    });
          //
          //}

          //$('.dropdown-menu a').bind('touchstart touchend click', function (event) {
          //    event.stopPropagation();
          //    location.href = $(this).attr('href');
          //});

          $(".dropdown-toggle").removeAttr('data-toggle');

          $(".dropdown").bind('touchstart touchend click', function (event) {
              console.log('clicked');
              $(this).find('.dropdown-menu').show();
          });

          $(document).mouseup(function (e)
          {
              var container = $(".dropdown-menu");

              if (!container.is(e.target) // if the target of the click isn't the container...
                  && container.has(e.target).length === 0) // ... nor a descendant of the container
              {
                  container.hide();
              }
          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
